import { Component, forwardRef, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseControlValueAccessor } from 'src/app/classes/base-control-value-accessor';

@Component({
  selector: 'app-inputArray',
  templateUrl: './inputArray.component.html',
  styleUrls: ['./inputArray.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputArrayComponent),
      multi: true,
    },
  ],
})

export class InputArrayComponent extends BaseControlValueAccessor<string[]> {
  @Input() label : string;
  @Input() parentFormGroup: FormGroup;
  @Input() inputArrayName: string;
  constructor(private fb: FormBuilder) {
    super();
  }
  get inputArray(): FormArray {
    return this.parentFormGroup.get(this.inputArrayName) as FormArray;
  }
  addWorkExperienceRecord(): void {
    this.inputArray.push(this.fb.control('', Validators.required));
  }

  removeWorkExperienceRecord(index: number): void {
    this.inputArray.removeAt(index);
  }
}