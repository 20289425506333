import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';

import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './shared/pages/app.notfound.component';
import {AppErrorComponent} from './shared/pages/app.error.component';
import {AppAccessdeniedComponent} from './shared/pages/app.accessdenied.component';
import {AppLoginComponent} from './shared/pages/app.login.component';
import { MsalGuard } from '@azure/msal-angular';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '',
                component: AppMainComponent,
                loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule),
            },
            {
                path: 'member',
                component: AppMainComponent,
                loadChildren: () => import('./modules/member/member.module').then(m => m.MemberModule),
                canActivate: [MsalGuard]
            },
            {path: 'error', component: AppErrorComponent},
            {path: 'accessdenied', component: AppAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            {path: 'login', component: AppLoginComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled', useHash: false})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
