<div class="field">
    <label [for]="formControlName" class="font-medium text-900">
        {{label}}
     </label>
    <input
        [id]="formControlName"
        pInputText
        [(ngModel)]="value"
        (ngModelChange)="onChange($event)"
        (blur)="onTouched()"
        [disabled]="isDisabled"
        class="p-inputtext p-component p-element"
        [class.ng-invalid]="control.invalid && (control.dirty || control.touched)"
        [class.ng-dirty]="control.invalid && (control.dirty || control.touched)"
    />
    <div *ngIf="control?.invalid && (control.dirty || control.touched)" class="text-red-50">
        <small class="text-red-400">{{errorMessage(control)}}</small>
    </div>
</div>
<!--[class.ng-invalid]="control?.invalid && (control.dirty || control.touched)"-->