<div class="field">
    <label [for]="formControlName" class="font-medium text-900">
        {{label}}
     </label>
    <p-calendar         
        [id]="formControlName"
        [(ngModel)]="value"
        (ngModelChange)="onChange($event)"
        (blur)="onTouched()"
        [disabled]="isDisabled"
        class=""
        [showIcon]="true"
        [class.ng-invalid]="control.invalid && (control.dirty || control.touched)"
        [class.ng-dirty]="control.invalid && (control.dirty || control.touched)">
    </p-calendar>
    <div *ngIf="control?.invalid && (control.dirty || control.touched)">
        <small class="text-red-400">{{errorMessage(control)}}</small>
    </div>
</div>