import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit {

  @Input() items: any[] = [];
  @Input() itemTemplate: TemplateRef<any>;
  placeholders: any[] = Array(6).fill(null);
  
  constructor() { }

  ngOnInit() {
  }

}
