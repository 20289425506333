import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from 'src/app/classes/base-control-value-accessor';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true,
    },
  ],
})

export class CalendarComponent extends BaseControlValueAccessor<Date> {
  @Input() label : string;
  @Input() formControlName : string;
  @Input() control: AbstractControl;
}