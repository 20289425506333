import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FullCalendarModule} from '@fullcalendar/angular';

// Application Components
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './shared/pages/app.notfound.component';
import {AppErrorComponent} from './shared/pages/app.error.component';
import {AppAccessdeniedComponent} from './shared/pages/app.accessdenied.component';
import {AppLoginComponent} from './shared/pages/app.login.component';
import {AppTimelineDemoComponent} from './shared/pages/app.timelinedemo.component';
import {AppMenuComponent} from './shared/layout/app.menu.component';
import {AppMenuitemComponent} from './shared/layout/app.menuitem.component';
import {AppBreadcrumbComponent} from './shared/layout/app.breadcrumb.component';
import {AppRightPanelComponent} from './shared/layout/app.rightpanel.component';
import {AppTopBarComponent} from './shared/layout/app.topbar.component';
import {AppFooterComponent} from './shared/layout/app.footer.component';

// Application services
import {BreadcrumbService} from './services/breadcrumb.service';
import {MenuService} from './services/app.menu.service';

import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './auth-config';
import { SharedModule } from './shared/shared.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ApiModule } from './api/api.module';
import { environment } from 'src/environments/environment';
import { ODataModule } from 'angular-odata';
import { MessageService } from 'primeng/api';
import { HttpLoadingInterceptor } from './interceptors/http-loading.interceptor.ts.service';

const modules = [
  SharedModule,
  CommonModule,
  BrowserModule,
  FormsModule,
  AppRoutingModule,
  RouterModule,
  HttpClientModule,
  BrowserAnimationsModule
]

@NgModule({
    imports: [
      ...modules,
      ApiModule.forRoot({ rootUrl: environment.apiUrl }),
      ODataModule.forRoot({ serviceRootUrl: `${environment.apiUrl}/odata` })
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppRightPanelComponent,
        AppBreadcrumbComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        AppLoginComponent,
        AppTimelineDemoComponent
    ],
    providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
      },
      {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
      },
      { 
        provide: HTTP_INTERCEPTORS, useClass: HttpLoadingInterceptor, multi: true 
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
      },
      MsalService,
      MsalGuard,
      MsalBroadcastService,MenuService, BreadcrumbService, MessageService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
