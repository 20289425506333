export const environment = {
  production: true,
  underConstruction: true,
  cloudInstance: '',
  tenant: '',
  authority: '',
  apiScope: '',
  clientId: '',
  apiUrl: '',
  msalConfig: {
      auth: {
          clientId: '',
      }
  },
  apiConfig: {
      scopes: [''],
      uri: ''
  },
  b2cPolicies: {
      names: {
          signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
          resetPassword: "B2C_1A_PASSWORDRESET",
          editProfile: ""
      },
      authorities: {
          signUpSignIn: {
              authority: 'https://minutriologo.b2clogin.com/minutriologo.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN'
          },
          resetPassword: {
              authority: 'https://minutriologo.b2clogin.com/minutriologo.onmicrosoft.com/B2C_1A_PASSWORDRESET'
          },
          editProfile: {
              authority: ""
          }
      },
      authorityDomain: "minutriologo.b2clogin.com"
  }
};
