<app-page-header title="Usuarios" [pageQuantity]="{
    icon: 'pi-users',
    quantity: rows.length,
    description: 'Active Users'
}"></app-page-header>
<p-table *ngIf="loading; else coreTable" [value]="skeletonRows" styleClass="p-datatable-gridlines"  responsiveLayout="scroll">
    <ng-template pTemplate="header">
        <tr>
            <th *ngFor="let col of cols">
                {{col.header}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body">
        <tr>
            <td *ngFor="let col of cols"><p-skeleton></p-skeleton></td>
        </tr>
    </ng-template>
</p-table>
<ng-template #coreTable>
    <p-table [value]="rows" electionMode="single" [rowHover]="true" styleClass="p-datatable-gridlines" [paginator]="false" [globalFilterFields]="['email']">
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of cols">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
            <tr (click)="handleRowClick(row)">
                <td *ngFor="let col of cols">
                        {{row[col.field]}}
                </td>
            </tr>
        </ng-template>
    </p-table>
</ng-template>