import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR, AbstractControl } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { BaseControlValueAccessor } from 'src/app/classes/base-control-value-accessor';

@Component({
  selector: 'app-hourPicker',
  templateUrl: './hourPicker.component.html',
  styleUrls: ['./hourPicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HourPickerComponent),
      multi: true
    }
  ]
})
export class HourPickerComponent extends BaseControlValueAccessor<number> implements OnInit {
  @Input() hours: SelectItem[] = [];
  @Input() formControlName: string;
  @Input() control: AbstractControl;

  ngOnInit() {}

  selectHour(hour: number) {
    this.value = hour;
    this.onChange(hour);
    this.onTouched();
  }
}
