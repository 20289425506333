<div class="field">
  <p-selectButton 
      [id]="formControlName"
      [options]="hours" 
      [(ngModel)]="value" 
      (ngModelChange)="onChange($event)"
      (blur)="onTouched()"
      [disabled]="isDisabled"
      optionLabel="label" 
      optionValue="value" 
      [class.ng-invalid]="control.invalid && (control.dirty || control.touched)"
      [class.ng-dirty]="control.dirty && control.touched"
      class="hour-picker"
  ></p-selectButton>
  <div *ngIf="control?.invalid && (control.dirty || control.touched)" class="text-red-50">
      <small class="text-red-400">{{errorMessage(control)}}</small>
  </div>
</div>
