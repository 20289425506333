import {Component} from '@angular/core';
import { ODataService } from 'src/app/services/odata.service';
import { environment } from 'src/environments/environment';
import {AppMainComponent} from '../../app.main.component';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-topbar',
    template: `
		<div *ngIf="underConstruction; else topBar" class="layout-topbar">
			<div class="layout-topbar-wrapper">
                <div class="layout-topbar-left">
					<div class="layout-topbar-logo-wrapper">
						<a *ngIf="appMain.landingActive" routerLink="/">
							<img width="30px" alt="logo" src="../assets/images/logo.png" />
						</a>
					</div>
                </div>
            </div>
        </div>
		<ng-template #topBar>
			<div class="layout-topbar">
				<div class="layout-topbar-wrapper">
					<div class="layout-topbar-left">
						<div class="layout-topbar-logo-wrapper">
							<a *ngIf="appMain.landingActive" routerLink="/">
								<img width="30px" alt="logo" src="../assets/images/logo.png" />
							</a>
						</div>
						<div class="login-button" *ngIf="!authService.loginDisplay; else authenticatedOptions">
							<a href="#" (click)="authService.login()">
								Inicia sesión
							</a>
						</div>
						<ng-template #authenticatedOptions>
							<a href="#" class="sidebar-menu-button" (click)="appMain.onMenuButtonClick($event)">
								<i class="pi pi-bars"></i>
							</a>
							<a href="#" class="topbar-menu-mobile-button" (click)="appMain.onTopbarMobileMenuButtonClick($event)">
								<i class="pi pi-ellipsis-v"></i>
							</a>
						</ng-template>
					</div>
					<div class="layout-topbar-right fadeInDown">
						<ul class="layout-topbar-actions">
							<div class="login-button" *ngIf="!authService.loginDisplay; else authenticatedMenu">
								<a href="#" id="cy-login" class="sidebar-menu-button" (click)="authService.login()">
									Inicia sesión
								</a>
							</div>
							<ng-template #authenticatedMenu>
								<li #message class="topbar-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === message}">
									<a href="#" (click)="appMain.onTopbarItemClick($event,message)">
										<i class="topbar-icon pi pi-inbox"></i>
									</a>
									<ul class="fadeInDown">
										<li class="layout-submenu-header">
											<h1>Messages</h1>
											<span>Today, you have new 4 messages</span>
										</li>
										<li class="layout-submenu-item">
											<img src="../../assets/layout/images/topbar/avatar-cayla.png" alt="mirage-layout" width="35" />
											<div class="menu-text">
												<p>Override the digital divide</p>
												<span>Cayla Brister</span>
											</div>
											<i class="pi pi-angle-right"></i>
										</li>
										<li class="layout-submenu-item">
											<img src="../../assets/layout/images/topbar/avatar-gabie.png" alt="mirage-layout" width="35" />
											<div class="menu-text">
												<p>Nanotechnology immersion</p>
												<span>Gabie Sheber</span>
											</div>
											<i class="pi pi-angle-right"></i>
										</li>
										<li class="layout-submenu-item">
											<img src="../../assets/layout/images/topbar/avatar-gaspar.png" alt="mirage-layout" width="35" />
											<div class="menu-text">
												<p>User generated content</p>
												<span>Gaspar Antunes</span>
											</div>
											<i class="pi pi-angle-right"></i>
										</li>
										<li class="layout-submenu-item">
											<img src="../../assets/layout/images/topbar/avatar-tatiana.png" alt="mirage-layout" width="35" />
											<div class="menu-text">
												<p>The holistic world view</p>
												<span>Tatiana Gagelman</span>
											</div>
											<i class="pi pi-angle-right"></i>
										</li>
									</ul>
								</li>
								<li #profile class="topbar-item profile-item" [ngClass]="{'active-topmenuitem': appMain.activeTopbarItem === profile}">
									<a href="#" (click)="appMain.onTopbarItemClick($event,profile)">
									<span class="profile-image-wrapper">
										<app-letter-avatar
													[firstWord]="authService.userData?.name"
													[secondWord]="authService.userData?.lastName">
										</app-letter-avatar>
										<!--<img src="../../assets/layout/images/topbar/avatar-eklund.png" alt="mirage-layout" />-->
									</span>
									</a>
									<ul class="profile-item-submenu fadeInDown">
										<li class="profile-submenu-header">
											<div class="profile">
												<!--
												<img src="../../assets/layout/images/topbar/avatar-eklund.png" alt="mirage-layout"
																width="40" />-->
												<app-letter-avatar
													[firstWord]="authService.userData?.name"
													[secondWord]="authService.userData?.lastName">
												</app-letter-avatar>
												<h1>{{authService.userData?.name | firstWord}} {{authService.userData?.lastName | firstWord}}</h1>
												<span>{{authService.userData?.email}}</span>
											</div>
										</li>
										<li class="layout-submenu-item">
											<i class="pi pi-list icon icon-1"></i>
											<div class="menu-text">
												<p>Tasks</p>
												<span>3 open issues</span>
											</div>
											<i class="pi pi-angle-right"></i>
										</li>
										<li class="layout-submenu-item">
											<i class="pi pi-shopping-cart icon icon-2"></i>
											<div class="menu-text">
												<p>Payments</p>
												<span>24 new</span>
											</div>
											<i class="pi pi-angle-right"></i>
										</li>
										<li class="layout-submenu-item">
											<i class="pi pi-users icon icon-3"></i>
											<div class="menu-text">
												<p>Clients</p>
												<span>+80%</span>
											</div>
											<i class="pi pi-angle-right"></i>
										</li>
										<li class="layout-submenu-footer">
											<button class="signout-button" (click)="logout()">Sign Out</button>
										</li>
									</ul>
								</li>
								<li class="topbar-item signout-item">
									<a href="#" class="sidebar-menu-button" (click)="logout()">
										<i class="pi pi-sign-out"></i>
									</a>
								</li>
							</ng-template>
						</ul>

					</div>
				</div>
			</div>
		</ng-template>
    `
})
export class AppTopBarComponent {
	underConstruction = environment.underConstruction;
    activeItem: number;
	user: any = {};
    constructor(public appMain: AppMainComponent, public authService: AuthService) {
	}

    mobileMegaMenuItemClick(index: any) {
        this.appMain.megaMenuMobileClick = true;
        this.activeItem = this.activeItem === index ? null : index;
    }

	logout() {
		this.appMain.topbarMobileMenuActive = false;
		this.authService.logout();
	}

}
