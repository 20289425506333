<ng-template #skeletonTemplate>
  <div class="surface-card shadow-2 border-round p-4">
    <div class="flex border-bottom-1 surface-border pb-4">
      <p-skeleton shape="circle" size="70px" class="mr-3"></p-skeleton>
      <div class="flex flex-column align-items-start">
        <p-skeleton height="1.5rem" width="50%" class="mb-1"></p-skeleton>
        <p-skeleton height="4rem" width="80%" class="mb-2"></p-skeleton>
        <p-skeleton height="1rem" width="50%"></p-skeleton>
      </div>
    </div>
    <div class="flex justify-content-between pt-4">
      <p-skeleton height="2rem" width="45%" class="mr-2"></p-skeleton>
      <p-skeleton height="2rem" width="45%" class="ml-2"></p-skeleton>
    </div>
  </div>
</ng-template>

<div class="grid">
  <ng-container *ngIf="items; else loading">
    <ng-container *ngFor="let item of items">
      <div class="col-12 md:col-6 xl:col-4 p-3">
        <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
      </div>
    </ng-container>
  </ng-container>
  
  <ng-template #loading>
    <ng-container *ngFor="let placeholder of placeholders">
      <div class="col-12 md:col-6 xl:col-4 p-3">
        <ng-container *ngTemplateOutlet="skeletonTemplate"></ng-container>
      </div>
    </ng-container>
  </ng-template>
</div>