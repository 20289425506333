/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ObjectResult } from '../models/object-result';
import { UpsertUserCommand } from '../models/upsert-user-command';
import { UserModel } from '../models/user-model';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiUserPost
   */
  static readonly ApiUserPostPath = '/api/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimal$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimal$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimal$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimal$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimal$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimal$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimal$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimal$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimal$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataMinimal$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataMinimal$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUserPost$JsonOdataMetadataMinimal$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataMinimal$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingTrue$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFullOdataStreamingFalse$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFull$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFull$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFull$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFull$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFull$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFull$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFull$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFull$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFull$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFull$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFull$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFull$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFull$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFull$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFull$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFull$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataFull$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataFull$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUserPost$JsonOdataMetadataFull$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataFull$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNone$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNone$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNone$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNone$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNone$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNone$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNone$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNone$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNone$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNone$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNone$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNone$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNone$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNone$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNone$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNone$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataMetadataNone$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataMetadataNone$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUserPost$JsonOdataMetadataNone$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataMetadataNone$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingTrue$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingTrue$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingTrue$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingTrue$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingTrue$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingTrue$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingTrue$Json()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingTrue$Json$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingTrue$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingTrue$Xml()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingTrue$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingTrue$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingTrue$Plain()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingTrue$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingTrue$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingTrue$Any()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingTrue$Any$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUserPost$JsonOdataStreamingTrue$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingTrue$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingFalse$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingFalse$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingFalse$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingFalse$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingFalse$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingFalse$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingFalse$Json()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingFalse$Json$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingFalse$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingFalse$Xml()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingFalse$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingFalse$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingFalse$Plain()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingFalse$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingFalse$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$JsonOdataStreamingFalse$Any()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$JsonOdataStreamingFalse$Any$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUserPost$JsonOdataStreamingFalse$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$JsonOdataStreamingFalse$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Json$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Json$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Json$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Json$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Json$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Json$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Json$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Json$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Json$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Json$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Json$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Json$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Json$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Json$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Json$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Json$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Json$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Json$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Json$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Json$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Json$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Json$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Json$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Json$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Json$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Json$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Json$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Json$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Json$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Json$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Json$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Json$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Json$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Json$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Json$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Json$Xml()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Json$Xml$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Json$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Json$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Json$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Json$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Json$Any()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Json$Any$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserPost$Json$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Json$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Xml$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Xml$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Xml$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Xml$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Xml$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Xml$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Xml$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Xml$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Xml$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Xml$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Xml$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Xml$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Xml$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Xml$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Xml$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Xml$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Xml$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Xml$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Xml$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Xml$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Xml$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Xml$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Xml$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Xml$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Xml$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Xml$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Xml$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Xml$Json()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Xml$Json$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Xml$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Xml$Xml()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Xml$Xml$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Xml$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Xml$Plain()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Xml$Plain$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Xml$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Xml$Any()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Xml$Any$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUserPost$Xml$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Xml$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Plain$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Plain$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Plain$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Plain$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Plain$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Plain$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Plain$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Plain$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Plain$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Plain$JsonOdataMetadataFull()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Plain$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Plain$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Plain$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Plain$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Plain$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Plain$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Plain$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Plain$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Plain$JsonOdataMetadataNone()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Plain$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Plain$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Plain$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Plain$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Plain$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Plain$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Plain$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Plain$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Plain$Json()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Plain$Json$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Plain$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Plain$Xml()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Plain$Xml$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Plain$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Plain$Plain()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Plain$Plain$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Plain$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPost$Plain$Any()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPost$Plain$Any$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUserPost$Plain$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUserPost$Plain$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * Path part for operation apiUpsertUserPost
   */
  static readonly ApiUpsertUserPostPath = '/api/upsert-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingTrue$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimalOdataStreamingFalse$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimal$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimal$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimal$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimal$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimal$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimal$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimal$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimal$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimal$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimal$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataMinimal$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataMinimal$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiUpsertUserPost$JsonOdataMetadataMinimal$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataMinimal$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingTrue$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFullOdataStreamingFalse$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFull$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFull$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFull$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFull$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFull$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFull$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFull$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFull$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFull$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFull$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataFull$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataFull$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiUpsertUserPost$JsonOdataMetadataFull$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataFull$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingTrue$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNoneOdataStreamingFalse$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNone$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNone$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNone$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNone$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNone$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNone$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNone$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNone$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNone$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNone$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataMetadataNone$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataMetadataNone$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiUpsertUserPost$JsonOdataMetadataNone$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataMetadataNone$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingTrue$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingTrue$Json()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingTrue$Json$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingTrue$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingTrue$Xml()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingTrue$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingTrue$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingTrue$Plain()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingTrue$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingTrue$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingTrue$Any()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingTrue$Any$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiUpsertUserPost$JsonOdataStreamingTrue$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingTrue$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingFalse$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingFalse$Json()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingFalse$Json$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingFalse$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingFalse$Xml()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingFalse$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingFalse$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingFalse$Plain()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingFalse$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingFalse$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$JsonOdataStreamingFalse$Any()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$JsonOdataStreamingFalse$Any$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiUpsertUserPost$JsonOdataStreamingFalse$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$JsonOdataStreamingFalse$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Json$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Json$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Json$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Json$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Json$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Json$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Json$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Json$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Json$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Json$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Json$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Json$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Json$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Json$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Json$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Json$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Json$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Json$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Json$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Json$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Json$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Json$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Json$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Json$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Json$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Json$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Json$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Json$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Json$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Json$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Json$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Json$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Json$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Json$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Json$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Json$Xml()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Json$Xml$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Json$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Json$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Json$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Json$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Json$Any()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Json$Any$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUpsertUserPost$Json$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Json$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Xml$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Xml$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Xml$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Xml$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Xml$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Xml$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Xml$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Xml$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Xml$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Xml$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Xml$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Xml$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Xml$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Xml$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Xml$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Xml$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Xml$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Xml$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Xml$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Xml$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Xml$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Xml$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Xml$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Xml$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Xml$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Xml$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Xml$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Xml$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Xml$Json()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Xml$Json$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Xml$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Xml$Xml()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Xml$Xml$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Xml$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Xml$Plain()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Xml$Plain$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Xml$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Xml$Any()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Xml$Any$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiUpsertUserPost$Xml$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Xml$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Plain$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Plain$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Plain$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Plain$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Plain$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Plain$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Plain$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Plain$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Plain$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Plain$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Plain$JsonOdataMetadataFull()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Plain$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Plain$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Plain$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Plain$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Plain$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Plain$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Plain$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Plain$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Plain$JsonOdataMetadataNone()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Plain$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Plain$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Plain$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Plain$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Plain$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Plain$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Plain$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Plain$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Plain$Json()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$Json$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Plain$Json$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$Json(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Plain$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Plain$Xml()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$Xml$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Plain$Xml$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$Xml(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Plain$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Plain$Plain()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$Plain$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Plain$Plain$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$Plain(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Plain$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpsertUserPost$Plain$Any()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$Any$Response(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpsertUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpsertUserPost$Plain$Any$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiUpsertUserPost$Plain$Any(params?: {
    context?: HttpContext
    body?: UpsertUserCommand
  }
): Observable<ObjectResult> {

    return this.apiUpsertUserPost$Plain$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * Path part for operation apiUserPermissionsGet
   */
  static readonly ApiUserPermissionsGetPath = '/api/user/permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserPermissionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserPermissionsGet$Response(params?: {
    Email?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUserPermissionsGetPath, 'get');
    if (params) {
      rb.query('Email', params.Email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserPermissionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserPermissionsGet(params?: {
    Email?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiUserPermissionsGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation odataUser
   */
  static readonly OdataUserPath = '/odata/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUser$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUser$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUser$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUser$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUser$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUser$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUser$JsonOdataMetadataMinimal()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUser$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUser$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUser$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUser$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUser$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUser$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUser$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUser$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUser$JsonOdataMetadataFull()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUser$JsonOdataMetadataFull$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataFull(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUser$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUser$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUser$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUser$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUser$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUser$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUser$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUser$JsonOdataMetadataNone()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUser$JsonOdataMetadataNone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataMetadataNone(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUser$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUser$JsonOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUser$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUser$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUser$JsonOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUser$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUser$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUser$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUser$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$Json(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUser$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUser$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$Xml$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUser$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$Xml(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUser$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUser$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUser$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUser$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUser$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$Any$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUser$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUser$Any(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUser$Any$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * Path part for operation odataUserCount
   */
  static readonly OdataUserCountPath = '/odata/user/$count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUserCount$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUserCount$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUserCount$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUserCount$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUserCount$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUserCount$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUserCount$JsonOdataMetadataMinimal()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUserCount$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUserCount$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUserCount$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUserCount$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUserCount$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUserCount$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUserCount$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUserCount$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUserCount$JsonOdataMetadataFull()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUserCount$JsonOdataMetadataFull$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataFull(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUserCount$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUserCount$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUserCount$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUserCount$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUserCount$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUserCount$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUserCount$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUserCount$JsonOdataMetadataNone()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUserCount$JsonOdataMetadataNone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataMetadataNone(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUserCount$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUserCount$JsonOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUserCount$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUserCount$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUserCount$JsonOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUserCount$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUserCount$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUserCount$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUserCount$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$Json(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUserCount$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUserCount$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$Xml$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUserCount$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$Xml(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUserCount$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUserCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUserCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUserCount$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataUserCount$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$Any$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.OdataUserCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataUserCount$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataUserCount$Any(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataUserCount$Any$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * Path part for operation apiUpHead
   */
  static readonly ApiUpHeadPath = '/api/up';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpHead()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUpHead$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiUpHeadPath, 'head');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUpHead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUpHead(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.apiUpHead$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
