<form [formGroup]="parentFormGroup">
    <div class="mb-2">
        <label class="font-medium text-900">{{label}}</label>
    </div>
    <div formArrayName="{{inputArrayName}}" *ngFor="let control of inputArray.controls; index as i" class="flex align-items-center mb-3">
        <input
            [id]="i"
            formControlName="{{i}}"
            pInputText
            class="p-inputtext p-component p-element"
        />
        <div *ngIf="control?.invalid && (control.dirty || control.touched)" class="text-red-50">
            <small class="text-red-400">{{errorMessage(control)}}</small>
        </div>
        <button pbutton="" pripple="" icon="pi pi-trash" (click)="removeWorkExperienceRecord(i)"
            class="p-element p-ripple p-button-danger p-button-text ml-2 p-button p-component p-button-icon-only">
            <span class="p-button-icon pi pi-trash" aria-hidden="true"></span>
            <span class="p-ink"></span>
        </button>
    </div>
</form>
 <button pButton pRipple label="Agregar" (click)="addWorkExperienceRecord()"
 class="mr-2 p-element p-ripple w-auto p-button-secondary p-button-outlined p-component" icon="pi pi-plus"></button>