/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { IEdmModel } from '../models/i-edm-model';
import { ODataServiceDocument } from '../models/o-data-service-document';

@Injectable({
  providedIn: 'root',
})
export class MetadataService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation odataMetadata
   */
  static readonly OdataMetadataPath = '/odata/$metadata';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataMetadata$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IEdmModel>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IEdmModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataMetadata$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<IEdmModel> {

    return this.odataMetadata$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<IEdmModel>) => r.body as IEdmModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataMetadata$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IEdmModel>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IEdmModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataMetadata$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<IEdmModel> {

    return this.odataMetadata$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<IEdmModel>) => r.body as IEdmModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataMetadata$JsonOdataMetadataMinimal()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IEdmModel>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IEdmModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataMetadata$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
  }
): Observable<IEdmModel> {

    return this.odataMetadata$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<IEdmModel>) => r.body as IEdmModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataMetadata$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IEdmModel>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IEdmModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataMetadata$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<IEdmModel> {

    return this.odataMetadata$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<IEdmModel>) => r.body as IEdmModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataMetadata$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IEdmModel>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IEdmModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataMetadata$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<IEdmModel> {

    return this.odataMetadata$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<IEdmModel>) => r.body as IEdmModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataMetadata$JsonOdataMetadataFull()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IEdmModel>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IEdmModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataMetadata$JsonOdataMetadataFull$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataFull(params?: {
    context?: HttpContext
  }
): Observable<IEdmModel> {

    return this.odataMetadata$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<IEdmModel>) => r.body as IEdmModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataMetadata$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IEdmModel>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IEdmModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataMetadata$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<IEdmModel> {

    return this.odataMetadata$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<IEdmModel>) => r.body as IEdmModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataMetadata$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IEdmModel>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IEdmModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataMetadata$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<IEdmModel> {

    return this.odataMetadata$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<IEdmModel>) => r.body as IEdmModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataMetadata$JsonOdataMetadataNone()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IEdmModel>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IEdmModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataMetadata$JsonOdataMetadataNone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataMetadataNone(params?: {
    context?: HttpContext
  }
): Observable<IEdmModel> {

    return this.odataMetadata$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<IEdmModel>) => r.body as IEdmModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataMetadata$JsonOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IEdmModel>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IEdmModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataMetadata$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<IEdmModel> {

    return this.odataMetadata$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<IEdmModel>) => r.body as IEdmModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataMetadata$JsonOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IEdmModel>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IEdmModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataMetadata$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<IEdmModel> {

    return this.odataMetadata$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<IEdmModel>) => r.body as IEdmModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataMetadata$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IEdmModel>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IEdmModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataMetadata$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$Json(params?: {
    context?: HttpContext
  }
): Observable<IEdmModel> {

    return this.odataMetadata$Json$Response(params).pipe(
      map((r: StrictHttpResponse<IEdmModel>) => r.body as IEdmModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataMetadata$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$Xml$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IEdmModel>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IEdmModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataMetadata$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$Xml(params?: {
    context?: HttpContext
  }
): Observable<IEdmModel> {

    return this.odataMetadata$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<IEdmModel>) => r.body as IEdmModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataMetadata$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IEdmModel>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IEdmModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataMetadata$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$Plain(params?: {
    context?: HttpContext
  }
): Observable<IEdmModel> {

    return this.odataMetadata$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<IEdmModel>) => r.body as IEdmModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataMetadata$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$Any$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<IEdmModel>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IEdmModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataMetadata$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataMetadata$Any(params?: {
    context?: HttpContext
  }
): Observable<IEdmModel> {

    return this.odataMetadata$Any$Response(params).pipe(
      map((r: StrictHttpResponse<IEdmModel>) => r.body as IEdmModel)
    );
  }

  /**
   * Path part for operation odata
   */
  static readonly OdataPath = '/odata';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odata$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ODataServiceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ODataServiceDocument>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odata$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<ODataServiceDocument> {

    return this.odata$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ODataServiceDocument>) => r.body as ODataServiceDocument)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odata$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ODataServiceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ODataServiceDocument>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odata$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<ODataServiceDocument> {

    return this.odata$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ODataServiceDocument>) => r.body as ODataServiceDocument)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odata$JsonOdataMetadataMinimal()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ODataServiceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ODataServiceDocument>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odata$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
  }
): Observable<ODataServiceDocument> {

    return this.odata$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ODataServiceDocument>) => r.body as ODataServiceDocument)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odata$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ODataServiceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ODataServiceDocument>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odata$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<ODataServiceDocument> {

    return this.odata$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ODataServiceDocument>) => r.body as ODataServiceDocument)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odata$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ODataServiceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ODataServiceDocument>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odata$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<ODataServiceDocument> {

    return this.odata$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ODataServiceDocument>) => r.body as ODataServiceDocument)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odata$JsonOdataMetadataFull()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ODataServiceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ODataServiceDocument>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odata$JsonOdataMetadataFull$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataFull(params?: {
    context?: HttpContext
  }
): Observable<ODataServiceDocument> {

    return this.odata$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ODataServiceDocument>) => r.body as ODataServiceDocument)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odata$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ODataServiceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ODataServiceDocument>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odata$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<ODataServiceDocument> {

    return this.odata$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ODataServiceDocument>) => r.body as ODataServiceDocument)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odata$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ODataServiceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ODataServiceDocument>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odata$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<ODataServiceDocument> {

    return this.odata$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ODataServiceDocument>) => r.body as ODataServiceDocument)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odata$JsonOdataMetadataNone()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ODataServiceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ODataServiceDocument>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odata$JsonOdataMetadataNone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataMetadataNone(params?: {
    context?: HttpContext
  }
): Observable<ODataServiceDocument> {

    return this.odata$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ODataServiceDocument>) => r.body as ODataServiceDocument)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odata$JsonOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ODataServiceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ODataServiceDocument>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odata$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<ODataServiceDocument> {

    return this.odata$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ODataServiceDocument>) => r.body as ODataServiceDocument)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odata$JsonOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ODataServiceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ODataServiceDocument>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odata$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<ODataServiceDocument> {

    return this.odata$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ODataServiceDocument>) => r.body as ODataServiceDocument)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odata$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ODataServiceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ODataServiceDocument>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odata$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$Json(params?: {
    context?: HttpContext
  }
): Observable<ODataServiceDocument> {

    return this.odata$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ODataServiceDocument>) => r.body as ODataServiceDocument)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odata$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$Xml$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ODataServiceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ODataServiceDocument>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odata$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$Xml(params?: {
    context?: HttpContext
  }
): Observable<ODataServiceDocument> {

    return this.odata$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ODataServiceDocument>) => r.body as ODataServiceDocument)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odata$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ODataServiceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ODataServiceDocument>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odata$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$Plain(params?: {
    context?: HttpContext
  }
): Observable<ODataServiceDocument> {

    return this.odata$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ODataServiceDocument>) => r.body as ODataServiceDocument)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odata$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$Any$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ODataServiceDocument>> {

    const rb = new RequestBuilder(this.rootUrl, MetadataService.OdataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ODataServiceDocument>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odata$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odata$Any(params?: {
    context?: HttpContext
  }
): Observable<ODataServiceDocument> {

    return this.odata$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ODataServiceDocument>) => r.body as ODataServiceDocument)
    );
  }

}
