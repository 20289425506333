import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

interface PageQuantity {
  icon: string,
  quantity: number,
  description: string
}

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() title = '';
  @Input() pageQuantity: PageQuantity;
  constructor(private router: Router, private route: ActivatedRoute) {
  }
  handleAddClick() {
    this.router.navigate(['0'], { relativeTo: this.route });
  }
}
