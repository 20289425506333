import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import { AuthService } from './services/auth.service';
import { LoadingService } from './services/loading.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
    horizontalMenu: boolean = false;

    darkMode = false;

    menuColorMode = 'light';

    menuColor = 'layout-menu-light';

    themeColor = 'blue';

    layoutColor = 'blue';

    ripple = true;

    inputStyle = 'outlined';

    loadingVisible = false;
    loadingValue = 100;
    loadingMode = 'indeterminate';
    constructor(
        private primengConfig: PrimeNGConfig,
        public authService: AuthService,
        private loadingService: LoadingService
    ) {
        this.loadingService.loading$.pipe(
            map(loading => {
                this.loadingVisible = loading;
            })
        ).subscribe();
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.authService.init();
    }
}
