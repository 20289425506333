import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-letter-avatar',
  templateUrl: './letter-avatar.component.html',
  styleUrls: ['./letter-avatar.component.scss']
})
export class LetterAvatarComponent {
  @Input() firstWord: string = '';
  @Input() secondWord: string = '';

  constructor() {
  }

  public get letterAvatar() {
    let firstLetter = this.firstWord?.charAt(0) ?? '';
    let secondLetter = this.secondWord?.charAt(0) ?? '';
    return `${firstLetter}${secondLetter}`;
  }
}
