import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { first } from 'rxjs';
import { MenuService } from '../../api/services';
import { AppMainComponent } from '../../app.main.component';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(public appMain: AppMainComponent, private menuService: MenuService) {}

    ngOnInit() {
        
        let cachedMenu = localStorage.getItem("mainMenu");
        if (cachedMenu) {
            this.model = JSON.parse(cachedMenu);
        } else {
            this.menuService.apiMenuGet$Json().pipe(first()).subscribe(
                (res: any) => {
                    this.model = this.getMenu(null, res.data);
                    localStorage.setItem("mainMenu", JSON.stringify(this.model));
                }
            );
        }
    }
    getMenu(menuItem: any, menuOptions: any): any {
        let childMenuItems: any[] = [];
        menuOptions.forEach(
            (x: any) => {
                // If root menu items
                // Or child menu item matches with parent
                if((!menuItem && !x.parentMenuId) || (menuItem && x.parentMenuId == menuItem.id)) {
                    let menuToAdd: any = {
                        label: x.label,
                        icon: x.icon,
                        routerLink: [x.link],
                    }
                    let childMenus = this.getMenu(x, menuOptions);
                    if(childMenus.length > 0)
                        menuToAdd.items = childMenus;
                    childMenuItems.push(menuToAdd);
                }
            }
        );
        return childMenuItems;
    }
    onMenuClick() {
        this.appMain.menuClick = true;
    }
}
