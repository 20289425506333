<div class="flex align-items-start flex-column lg:justify-content-between lg:flex-row mb-5">
    <div>
        <div class="font-medium text-3xl text-900">{{title}}</div>
        <div class="flex align-items-center text-700 flex-wrap" *ngIf="pageQuantity">
            <div class="mr-5 flex align-items-center mt-3">
                <i class="pi pi-users mr-2" [ngClass]="pageQuantity.icon"></i>
                <span>{{pageQuantity.quantity}} {{pageQuantity.description}}</span>
            </div>
        </div>
    </div>
    <div class="mt-3 lg:mt-0">
        <button (click)="handleAddClick()" id="cy-add" pButton pRipple label="Add" class="p-button-outlined mr-2" icon="pi pi-user-plus">

        </button>
    </div>
</div>