import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective {
  private elseTemplate: TemplateRef<any> | null = null;
  private _role: number | null = null;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {}

  @Input() set appHasRole(role: number) {
    this._role = role;
    this.updateView();
  }

  @Input() set appHasRoleElse(template: TemplateRef<any> | null) {
    this.elseTemplate = template;
    this.updateView();
  }

  private updateView() {
    if (this._role === null) return;

    const rolesString: string = this.authService.idTokenClaims?.roles || '';
    const userRoles: number[] = rolesString.split(' ').map(Number);
    
    this.viewContainer.clear();
    if (userRoles.includes(this._role)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.elseTemplate) {
      this.viewContainer.createEmbeddedView(this.elseTemplate);
    }
  }
}
