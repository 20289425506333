import { Injectable } from '@angular/core';
import { first, map, Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { ODataServiceFactory } from 'angular-odata';

@Injectable({providedIn: 'root'})
export class ODataService {
    constructor(private factory: ODataServiceFactory) {

    }
    getEntityData(entityName: string, filter?: any, expand?: object) {
      let entityService = this.factory.entitySet(entityName);
      let entity = entityService.entities().clone()
        .query((q) => {
          if (expand) {
            q.expand(expand);
          }
          if (filter) {
            q.filter(filter);
          }
        });
      return entity.fetch().pipe(map((x: any) => x.entities)).pipe(first());
    }
    getEntityDataById(entityName: string, filter: any, expand?: object, guid = false) {
      let entityService = this.factory.entitySet(entityName);
      let entity = entityService.entities().clone()
        .query((q) => {
          if (expand) {
            q.expand(expand);
          }
          if (filter) {
            // Failing with guids
            if(!guid) {
              q.filter(filter);
            } else {
              // This will set the value without the quotes
              const filterString = Object.keys(filter)
                .map(key => `${key} eq ${filter[key]}`)
                .join(' and ');
              q.filter(filterString);
            }
          }
          
        });
      return entity.fetch()
        .pipe(map((x: any) => (x.entities.length > 0) ? x.entities[0] : null))
        .pipe(first());
    }
    private itemsSource = new Subject<MenuItem[]>();

    itemsHandler = this.itemsSource.asObservable();

    setItems(items: MenuItem[]) {
        this.itemsSource.next(items);
    }
}
