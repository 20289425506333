import { AbstractControl, ControlValueAccessor } from '@angular/forms';

export abstract class BaseControlValueAccessor<T> implements ControlValueAccessor {
  value: T;
  isDisabled: boolean;

  private onChangeFn = (_: T) => {};
  private onTouchedFn = () => {};

  writeValue(value: T): void {
    this.value = value;
  }

  registerOnChange(fn: (_: T) => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange(value: T): void {
    this.onChangeFn(value);
  }

  onTouched(): void {
    this.onTouchedFn();
  }
  
  errorMessage(control: AbstractControl): string {
    if (control.errors) {
      if (control.errors['required']) {
        return 'This field is required.';
      } else if(control.errors['email']) {
        return 'Invalid email.';
      }
      // Add additional conditions to check for other error types
    }
    return '';
  }
}