/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AppointmentCommand } from '../models/appointment-command';
import { AppointmentModel } from '../models/appointment-model';
import { ObjectResult } from '../models/object-result';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiAppointmentPost
   */
  static readonly ApiAppointmentPostPath = '/api/appointment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Json$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Json(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Xml$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Xml(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Plain$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Plain(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Any$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=true` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Any(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingTrue$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Json$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Json(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Xml$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Xml(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Plain$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Plain(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Any$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal;odata.streaming=false` and handles request body of type `application/json;odata.metadata=minimal;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Any(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimalOdataStreamingFalse$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimal$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimal$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$Json$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimal$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$Json(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimal$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimal$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$Xml$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimal$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$Xml(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimal$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimal$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$Plain$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimal$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$Plain(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimal$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataMinimal$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$Any$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=minimal');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataMinimal$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=minimal` and handles request body of type `application/json;odata.metadata=minimal`.
   */
  apiAppointmentPost$JsonOdataMetadataMinimal$Any(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataMinimal$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Json$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Json(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Xml$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Xml(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Plain$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Plain(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Any$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=true` and handles request body of type `application/json;odata.metadata=full;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Any(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingTrue$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Json$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Json(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Xml$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Xml(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Plain$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Plain(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Any$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full;odata.streaming=false` and handles request body of type `application/json;odata.metadata=full;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Any(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFullOdataStreamingFalse$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFull$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFull$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFull$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFull$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFull$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$Json$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFull$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$Json(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFull$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFull$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$Xml$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFull$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$Xml(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFull$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFull$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$Plain$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFull$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$Plain(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFull$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataFull$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$Any$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=full');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataFull$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=full` and handles request body of type `application/json;odata.metadata=full`.
   */
  apiAppointmentPost$JsonOdataMetadataFull$Any(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataFull$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Json$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Json(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Xml$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Xml(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Plain$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Plain(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Any$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=true` and handles request body of type `application/json;odata.metadata=none;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Any(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingTrue$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Json$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Json(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Xml$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Xml(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Plain$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Plain(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Any$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none;odata.streaming=false` and handles request body of type `application/json;odata.metadata=none;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Any(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNoneOdataStreamingFalse$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNone$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNone$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNone$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNone$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNone$Json()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$Json$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNone$Json$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$Json(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNone$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNone$Xml()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$Xml$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNone$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$Xml(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNone$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNone$Plain()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$Plain$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNone$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$Plain(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNone$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataMetadataNone$Any()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$Any$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.metadata=none');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataMetadataNone$Any$Response()` instead.
   *
   * This method sends `application/json;odata.metadata=none` and handles request body of type `application/json;odata.metadata=none`.
   */
  apiAppointmentPost$JsonOdataMetadataNone$Any(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataMetadataNone$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingTrue$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingTrue$Json()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$Json$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingTrue$Json$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$Json(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingTrue$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingTrue$Xml()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$Xml$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingTrue$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$Xml(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingTrue$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingTrue$Plain()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$Plain$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingTrue$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$Plain(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingTrue$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingTrue$Any()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$Any$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=true');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingTrue$Any$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=true` and handles request body of type `application/json;odata.streaming=true`.
   */
  apiAppointmentPost$JsonOdataStreamingTrue$Any(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingTrue$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingFalse$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingFalse$Json()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$Json$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingFalse$Json$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$Json(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingFalse$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingFalse$Xml()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$Xml$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingFalse$Xml$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$Xml(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingFalse$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingFalse$Plain()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$Plain$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingFalse$Plain$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$Plain(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingFalse$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$JsonOdataStreamingFalse$Any()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$Any$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json;odata.streaming=false');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$JsonOdataStreamingFalse$Any$Response()` instead.
   *
   * This method sends `application/json;odata.streaming=false` and handles request body of type `application/json;odata.streaming=false`.
   */
  apiAppointmentPost$JsonOdataStreamingFalse$Any(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$JsonOdataStreamingFalse$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Json$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Json$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Json$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Json$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Json$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Json$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Json$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Json$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Json$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Json$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Json$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Json$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Json$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Json$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Json$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Json$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Json$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Json$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Json$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Json$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Json$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Json$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Json$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Json$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Json$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Json$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Json$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Json$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Json$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Json$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Json$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Json$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Json$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Json$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$Json$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Json$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$Json(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Json$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Json$Xml()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$Xml$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Json$Xml$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$Xml(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Json$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Json$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$Plain$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Json$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$Plain(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Json$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Json$Any()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$Any$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Json$Any$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAppointmentPost$Json$Any(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Json$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Xml$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Xml$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Xml$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Xml$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Xml$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Xml$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Xml$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Xml$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Xml$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Xml$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Xml$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Xml$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Xml$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Xml$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Xml$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Xml$JsonOdataMetadataFull()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Xml$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Xml$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Xml$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Xml$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Xml$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Xml$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Xml$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Xml$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Xml$JsonOdataMetadataNone()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Xml$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Xml$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Xml$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Xml$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Xml$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Xml$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Xml$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Xml$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Xml$Json()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$Json$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Xml$Json$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$Json(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Xml$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Xml$Xml()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$Xml$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Xml$Xml$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$Xml(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Xml$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Xml$Plain()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$Plain$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Xml$Plain$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$Plain(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Xml$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Xml$Any()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$Any$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/xml');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Xml$Any$Response()` instead.
   *
   * This method sends `application/xml` and handles request body of type `application/xml`.
   */
  apiAppointmentPost$Xml$Any(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Xml$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Plain$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Plain$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Plain$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Plain$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Plain$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Plain$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Plain$JsonOdataMetadataMinimal()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Plain$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Plain$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Plain$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Plain$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Plain$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Plain$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Plain$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Plain$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Plain$JsonOdataMetadataFull()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Plain$JsonOdataMetadataFull$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataFull(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Plain$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Plain$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Plain$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Plain$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Plain$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Plain$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Plain$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Plain$JsonOdataMetadataNone()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Plain$JsonOdataMetadataNone$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataMetadataNone(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Plain$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Plain$JsonOdataStreamingTrue()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Plain$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Plain$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Plain$JsonOdataStreamingFalse()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Plain$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Plain$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Plain$Json()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$Json$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Plain$Json$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$Json(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Plain$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Plain$Xml()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$Xml$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Plain$Xml$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$Xml(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Plain$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Plain$Plain()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$Plain$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Plain$Plain$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$Plain(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Plain$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppointmentPost$Plain$Any()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$Any$Response(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<StrictHttpResponse<ObjectResult>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.ApiAppointmentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ObjectResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAppointmentPost$Plain$Any$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  apiAppointmentPost$Plain$Any(params?: {
    context?: HttpContext
    body?: AppointmentCommand
  }
): Observable<ObjectResult> {

    return this.apiAppointmentPost$Plain$Any$Response(params).pipe(
      map((r: StrictHttpResponse<ObjectResult>) => r.body as ObjectResult)
    );
  }

  /**
   * Path part for operation odataAppointment
   */
  static readonly OdataAppointmentPath = '/odata/appointment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointment$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointment$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointment$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointment$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointment$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointment$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointment$JsonOdataMetadataMinimal()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointment$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointment$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointment$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointment$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointment$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointment$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointment$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointment$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointment$JsonOdataMetadataFull()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointment$JsonOdataMetadataFull$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataFull(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointment$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointment$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointment$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointment$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointment$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointment$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointment$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointment$JsonOdataMetadataNone()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointment$JsonOdataMetadataNone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataMetadataNone(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointment$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointment$JsonOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointment$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointment$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointment$JsonOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointment$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointment$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointment$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointment$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$Json(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointment$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointment$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$Xml$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointment$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$Xml(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointment$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointment$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointment$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointment$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointment$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$Any$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointment$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointment$Any(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointment$Any$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * Path part for operation odataAppointmentCount
   */
  static readonly OdataAppointmentCountPath = '/odata/appointment/$count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointmentCount$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointmentCount$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointmentCount$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointmentCount$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointmentCount$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointmentCount$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointmentCount$JsonOdataMetadataMinimal()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointmentCount$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointmentCount$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointmentCount$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointmentCount$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointmentCount$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointmentCount$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointmentCount$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointmentCount$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointmentCount$JsonOdataMetadataFull()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointmentCount$JsonOdataMetadataFull$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataFull(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointmentCount$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointmentCount$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointmentCount$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointmentCount$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointmentCount$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointmentCount$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointmentCount$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointmentCount$JsonOdataMetadataNone()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointmentCount$JsonOdataMetadataNone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataMetadataNone(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointmentCount$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointmentCount$JsonOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointmentCount$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointmentCount$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointmentCount$JsonOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointmentCount$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointmentCount$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointmentCount$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointmentCount$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$Json(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointmentCount$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointmentCount$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$Xml$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointmentCount$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$Xml(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointmentCount$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointmentCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointmentCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointmentCount$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataAppointmentCount$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$Any$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AppointmentModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AppointmentService.OdataAppointmentCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AppointmentModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataAppointmentCount$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataAppointmentCount$Any(params?: {
    context?: HttpContext
  }
): Observable<Array<AppointmentModel>> {

    return this.odataAppointmentCount$Any$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AppointmentModel>>) => r.body as Array<AppointmentModel>)
    );
  }

}
