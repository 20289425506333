import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FirstWordPipe } from '../pipes/firstWord.pipe';
import { RemainingTimePipe } from '../pipes/remainingTime.pipe';
import { CardListComponent } from './components/card-list/card-list.component';
import { CoreTableColumnDirective, CoreTableComponent } from './components/core-table/core-table.component';
import { LetterAvatarComponent } from './components/letter-avatar/letter-avatar.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { HasRoleDirective } from './directives/has-role.directive';
import { CalendarComponent } from './forms/calendar/calendar.component';
import { HourPickerComponent } from './forms/hourPicker/hourPicker.component';
import { InputComponent } from './forms/input/input.component';
import { InputArrayComponent } from './forms/inputArray/inputArray.component';
import { MultiselectComponent } from './forms/multiselect/multiselect.component';
import { TextAreaComponent } from './forms/textArea/textArea.component';
import { PrimeNgModule } from './primeng.module';

const modules = [PrimeNgModule, FormsModule, ReactiveFormsModule];
const components: any[] = [
    // Core Components
    CoreTableComponent, 
    CoreTableColumnDirective,
    LetterAvatarComponent,
    PageHeaderComponent,
    CardListComponent,
    // Inputs
    InputComponent,
    InputArrayComponent,
    TextAreaComponent,
    CalendarComponent,
    MultiselectComponent,
    HourPickerComponent,
    // Pipes
    RemainingTimePipe
];

const pipes: any[] = [FirstWordPipe];
const directives: any[] = [HasRoleDirective];

@NgModule({
 imports:      [...modules],
 declarations: [...components, ...pipes, ...directives],
 exports:      [...components, ...pipes, ...modules, ...directives]
})
export class SharedModule { }