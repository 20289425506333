import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from 'src/app/classes/base-control-value-accessor';

@Component({
  selector: 'app-textArea',
  templateUrl: './textArea.component.html',
  styleUrls: ['./textArea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})

export class TextAreaComponent extends BaseControlValueAccessor<string> {
  @Input() label : string;
  @Input() formControlName : string;
  @Input() control: AbstractControl;
}