import { Component, ContentChildren, Directive, Input, OnInit, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ODataService } from 'src/app/services/odata.service';
@Directive({
  selector: 'core-table-column'
})
export class CoreTableColumnDirective {
  @Input() displayName = '';
  @Input() path = '';
}

@Component({
  selector: 'core-table',
  templateUrl: './core-table.component.html',
  styleUrls: ['./core-table.component.scss']
})
export class CoreTableComponent implements OnInit {
  constructor(private ODataService: ODataService, private router: Router, private route: ActivatedRoute) { }
  @Input() entity = '';
  cars = [{
    vin: 'VIN',
    year: 'Year',
    brand: 'Brand',
    color: 'Color'
  }];
  
  cols = [
    { field: 'email', header: 'Email' }
  ];
  rows: any[] = [];
  skeletonRows: any[] = [1,2,3];
  loading = false;
  @ContentChildren(CoreTableColumnDirective) columnsDirective: QueryList<CoreTableColumnDirective>
  ngOnInit() {
    this.loading = true;
    this.ODataService.getEntityData(this.entity).subscribe({
      next: (entities : any[]) => {
        this.rows = entities;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }
  ngAfterContentInit() {
    this.cols = this.columnsDirective.map(
      (x) => {
        return { header: x.displayName, field: x.path };
      }
    );
  }
  handleRowClick($event: any) {
    this.router.navigate([$event.id], { relativeTo: this.route });
  }
}
