import { Component } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent {

  dark: boolean;

  checked: boolean;

}
