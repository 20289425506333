import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'remainingTime'
})
export class RemainingTimePipe implements PipeTransform {

  transform(startDateTime: string): string {
    const now = new Date();
    const appointmentTime = new Date(startDateTime);
    const differenceInMilliseconds = appointmentTime.getTime() - now.getTime();

    if (differenceInMilliseconds < 0) {
      return 'Appointment has started';
    }

    const differenceInSeconds = differenceInMilliseconds / 1000;
    const minutes = Math.floor(differenceInSeconds / 60);
    const hours = Math.floor(minutes / 60);

    return `${hours} hours and ${minutes % 60} minutes remaining`;
  }

}