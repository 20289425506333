/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserModelResult } from '../models/user-model-result';

@Injectable({
  providedIn: 'root',
})
export class MenuService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMenuGet
   */
  static readonly ApiMenuGetPath = '/api/menu';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMenuGet$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserModelResult>> {

    const rb = new RequestBuilder(this.rootUrl, MenuService.ApiMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModelResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMenuGet$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<UserModelResult> {

    return this.apiMenuGet$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<UserModelResult>) => r.body as UserModelResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMenuGet$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserModelResult>> {

    const rb = new RequestBuilder(this.rootUrl, MenuService.ApiMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModelResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMenuGet$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<UserModelResult> {

    return this.apiMenuGet$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<UserModelResult>) => r.body as UserModelResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMenuGet$JsonOdataMetadataMinimal()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserModelResult>> {

    const rb = new RequestBuilder(this.rootUrl, MenuService.ApiMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModelResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMenuGet$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
  }
): Observable<UserModelResult> {

    return this.apiMenuGet$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<UserModelResult>) => r.body as UserModelResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMenuGet$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserModelResult>> {

    const rb = new RequestBuilder(this.rootUrl, MenuService.ApiMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModelResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMenuGet$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<UserModelResult> {

    return this.apiMenuGet$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<UserModelResult>) => r.body as UserModelResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMenuGet$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserModelResult>> {

    const rb = new RequestBuilder(this.rootUrl, MenuService.ApiMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModelResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMenuGet$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<UserModelResult> {

    return this.apiMenuGet$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<UserModelResult>) => r.body as UserModelResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMenuGet$JsonOdataMetadataFull()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserModelResult>> {

    const rb = new RequestBuilder(this.rootUrl, MenuService.ApiMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModelResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMenuGet$JsonOdataMetadataFull$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataFull(params?: {
    context?: HttpContext
  }
): Observable<UserModelResult> {

    return this.apiMenuGet$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<UserModelResult>) => r.body as UserModelResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMenuGet$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserModelResult>> {

    const rb = new RequestBuilder(this.rootUrl, MenuService.ApiMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModelResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMenuGet$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<UserModelResult> {

    return this.apiMenuGet$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<UserModelResult>) => r.body as UserModelResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMenuGet$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserModelResult>> {

    const rb = new RequestBuilder(this.rootUrl, MenuService.ApiMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModelResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMenuGet$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<UserModelResult> {

    return this.apiMenuGet$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<UserModelResult>) => r.body as UserModelResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMenuGet$JsonOdataMetadataNone()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserModelResult>> {

    const rb = new RequestBuilder(this.rootUrl, MenuService.ApiMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModelResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMenuGet$JsonOdataMetadataNone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataMetadataNone(params?: {
    context?: HttpContext
  }
): Observable<UserModelResult> {

    return this.apiMenuGet$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<UserModelResult>) => r.body as UserModelResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMenuGet$JsonOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserModelResult>> {

    const rb = new RequestBuilder(this.rootUrl, MenuService.ApiMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModelResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMenuGet$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<UserModelResult> {

    return this.apiMenuGet$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<UserModelResult>) => r.body as UserModelResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMenuGet$JsonOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserModelResult>> {

    const rb = new RequestBuilder(this.rootUrl, MenuService.ApiMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModelResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMenuGet$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<UserModelResult> {

    return this.apiMenuGet$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<UserModelResult>) => r.body as UserModelResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMenuGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserModelResult>> {

    const rb = new RequestBuilder(this.rootUrl, MenuService.ApiMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModelResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMenuGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$Json(params?: {
    context?: HttpContext
  }
): Observable<UserModelResult> {

    return this.apiMenuGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserModelResult>) => r.body as UserModelResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMenuGet$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$Xml$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserModelResult>> {

    const rb = new RequestBuilder(this.rootUrl, MenuService.ApiMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModelResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMenuGet$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$Xml(params?: {
    context?: HttpContext
  }
): Observable<UserModelResult> {

    return this.apiMenuGet$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<UserModelResult>) => r.body as UserModelResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMenuGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserModelResult>> {

    const rb = new RequestBuilder(this.rootUrl, MenuService.ApiMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModelResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMenuGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<UserModelResult> {

    return this.apiMenuGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserModelResult>) => r.body as UserModelResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMenuGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$Any$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserModelResult>> {

    const rb = new RequestBuilder(this.rootUrl, MenuService.ApiMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserModelResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMenuGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMenuGet$Any(params?: {
    context?: HttpContext
  }
): Observable<UserModelResult> {

    return this.apiMenuGet$Any$Response(params).pipe(
      map((r: StrictHttpResponse<UserModelResult>) => r.body as UserModelResult)
    );
  }

}
