/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserModel } from '../models/user-model';

@Injectable({
  providedIn: 'root',
})
export class NutritionistService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation odataNutritionist
   */
  static readonly OdataNutritionistPath = '/odata/nutritionist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionist$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionist$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionist$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionist$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionist$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionist$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionist$JsonOdataMetadataMinimal()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionist$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionist$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionist$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionist$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionist$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionist$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionist$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionist$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionist$JsonOdataMetadataFull()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionist$JsonOdataMetadataFull$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataFull(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionist$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionist$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionist$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionist$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionist$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionist$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionist$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionist$JsonOdataMetadataNone()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionist$JsonOdataMetadataNone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataMetadataNone(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionist$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionist$JsonOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionist$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionist$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionist$JsonOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionist$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionist$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionist$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionist$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$Json(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionist$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionist$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$Xml$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionist$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$Xml(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionist$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionist$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionist$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionist$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionist$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$Any$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionist$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionist$Any(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionist$Any$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * Path part for operation odataNutritionistCount
   */
  static readonly OdataNutritionistCountPath = '/odata/nutritionist/$count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionistCount$JsonOdataMetadataMinimalOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionistCount$JsonOdataMetadataMinimalOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataMinimalOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionistCount$JsonOdataMetadataMinimalOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionistCount$JsonOdataMetadataMinimalOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionistCount$JsonOdataMetadataMinimalOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataMinimalOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionistCount$JsonOdataMetadataMinimalOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionistCount$JsonOdataMetadataMinimal()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataMinimal$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;minimal',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionistCount$JsonOdataMetadataMinimal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataMinimal(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionistCount$JsonOdataMetadataMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionistCount$JsonOdataMetadataFullOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataFullOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionistCount$JsonOdataMetadataFullOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataFullOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionistCount$JsonOdataMetadataFullOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionistCount$JsonOdataMetadataFullOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataFullOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionistCount$JsonOdataMetadataFullOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataFullOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionistCount$JsonOdataMetadataFullOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionistCount$JsonOdataMetadataFull()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataFull$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;full',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionistCount$JsonOdataMetadataFull$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataFull(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionistCount$JsonOdataMetadataFull$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionistCount$JsonOdataMetadataNoneOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataNoneOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionistCount$JsonOdataMetadataNoneOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataNoneOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionistCount$JsonOdataMetadataNoneOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionistCount$JsonOdataMetadataNoneOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataNoneOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionistCount$JsonOdataMetadataNoneOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataNoneOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionistCount$JsonOdataMetadataNoneOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionistCount$JsonOdataMetadataNone()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataNone$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.metadata&#x3D;none',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionistCount$JsonOdataMetadataNone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataMetadataNone(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionistCount$JsonOdataMetadataNone$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionistCount$JsonOdataStreamingTrue()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataStreamingTrue$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;true',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionistCount$JsonOdataStreamingTrue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataStreamingTrue(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionistCount$JsonOdataStreamingTrue$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionistCount$JsonOdataStreamingFalse()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataStreamingFalse$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json;odata.streaming&#x3D;false',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionistCount$JsonOdataStreamingFalse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$JsonOdataStreamingFalse(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionistCount$JsonOdataStreamingFalse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionistCount$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionistCount$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$Json(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionistCount$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionistCount$Xml()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$Xml$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/xml',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionistCount$Xml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$Xml(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionistCount$Xml$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionistCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionistCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionistCount$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `odataNutritionistCount$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$Any$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<UserModel>>> {

    const rb = new RequestBuilder(this.rootUrl, NutritionistService.OdataNutritionistCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `odataNutritionistCount$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  odataNutritionistCount$Any(params?: {
    context?: HttpContext
  }
): Observable<Array<UserModel>> {

    return this.odataNutritionistCount$Any$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserModel>>) => r.body as Array<UserModel>)
    );
  }

}
