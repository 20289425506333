import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from 'src/app/classes/base-control-value-accessor';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectComponent),
      multi: true,
    },
  ],
})

export class MultiselectComponent extends BaseControlValueAccessor<any[]> {
  @Input() label : string;
  @Input() formControlName : string;
  @Input() options : any[];
  @Input() control: AbstractControl;
}